<div class="orders-list">
  <div class="orders-list-title">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="back d-flex align-items-center">
          <img src="/assets/images/icons/back.svg" class="cursor-pointer  d-md-block d-none"
               [routerLink]="['/admin', 'dashboard']"/>
          <p class="title-back mb-0 fs-16 cursor-pointer  d-md-block d-none"
             [routerLink]="['/admin', 'dashboard']">{{'back' | translate}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="orders-list-wrapper">
    <h2 class="fs-32 fw-bold ps-sm-0 ps-4">{{'orders' | translate}}</h2>
    <div class="custom-table">
      <div class="tag-filters">
        <span class="tag" [ngClass]="{'active': selectedFilter === filter.value}"
              (click)="filterOrders(filter.value)"
              *ngFor="let filter of filters">{{filter.label}}</span>
      </div>
      <kwot-custom-table
        #customTableComponent
        [customOptions]="customDatatableSettings"
        [ajaxFunction]="getAllOrders"
        [tableHead]="tableHead" [tableBody]="tableBody">
      </kwot-custom-table>
      <ng-template #tableHead>
        <thead *ngIf="!isMobile">
        <tr>
          <td>
            <p
              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
              {{'buyer' | translate}}
            </p>
          </td>
          <td>
            <p
              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
              {{'seller' | translate}}
            </p>
          </td>
          <td>
            <p
              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
              {{'order' | translate}}
            </p>
          </td>
          <td>
            <p
              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
              {{'date_time' | translate}}
            </p>
          </td>
          <td>
            <p
              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
              {{'status' | translate}}
            </p>
          </td>
          <td>
            <p
              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
              {{'products' | translate}}
            </p>
          </td>
          <td>
            <p
              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
              {{'price' | translate}}
            </p>
          </td>
        </tr>
        </thead>
      </ng-template>
      <ng-template #tableBody>
        <tbody *ngIf="ordersList.length <= 0">
        <tr>
          <td colspan="7">{{'no_data_found' | translate}}</td>
        </tr>
        </tbody>
        <tbody *ngIf="ordersList.length > 0  && !isMobile">
        <tr *ngFor="let order of ordersList">
          <td>
            <p class="mb-0 fs-16 cursor-pointer store-content-details"
               [routerLink]="['/admin', 'orders', 'order-info', order._id]">
              {{order?.user?.firstName}} {{order?.user?.lastName}}
            </p>
          </td>
          <td>
            <p class="mb-0 fs-16 cursor-pointer store-content-details" *ngIf="!order?.type"
               [routerLink]="['/admin', 'orders', 'order-info', order._id]">
              <img [lazyLoad]="order.seller?.logo || order.seller?.image"
                   *ngIf="(order.seller?.logo || order.seller?.image)"
                   [defaultImage]="'/assets/images/seller_logo.png'" class="search-icon me-2" alt="">
              <span class="store-name-logo list-view" *ngIf="!(order.seller?.logo || order.seller?.image)">
                {{order.seller?.name?.substring(0, 2) | uppercase}}</span>
              {{order.seller?.name}}
            </p>
            <p class="mb-0 fs-16 cursor-pointer store-content-details" *ngIf="order?.type && order.type === 'digital'"
               [routerLink]="['/admin', 'orders', 'order-info', order._id]">
              -
            </p>
          </td>
          <td>
            <p class="mb-0 fs-16 store-content-details">
              #{{order.orderNo}}
            </p>
          </td>
          <td>
            <p class="mb-0 fs-16 store-content-details">
              {{order.createdAt | date: 'dd/MM/yyyy'}}
            </p>
          </td>
          <td>
            <p class="mb-0 fs-16 store-content-details">
              <span class="status {{orderStatus[order.status] | slugify}}" *ngIf="!order?.isReturning">
                <span
                  class="badge"></span>{{order.status == 5 ? ('canceled_by_buyer' | translate) : order.status == 2 ? ('canceled_by_vendor' | translate) : orderStatus[order.status]}}
              </span>
              <span class="status {{returnOrderStatus[order.returnStatus] | slugify}}" *ngIf="order?.isReturning">
                <span class="badge"></span>{{returnOrderStatus[order.returnStatus]}}
              </span>
            </p>
          </td>
          <td>
            <p class="mb-0 fs-16 store-content-details" *ngIf="!order?.type">
              {{order?.product?.productName}}
            </p>
            <p class="mb-0 fs-16 store-content-details" *ngIf="order?.type && order?.type === 'digital'">
              {{'gift_card' | translate}}
            </p>
          </td>
          <td>
            <ng-container *ngIf="selectedFilter === 'refund'">
              <kwot-currency [price]="order.refundPrice" tag="p"
                             class="mb-0 fs-16 store-content-details" [hasField]="false"
                             type="order_static" [item]="order"
                             [platformFee]="false"></kwot-currency>
              <span class="fs-14 text-capitalize">{{order.paymentOption}}
                :- {{order.paymentOption === 'alatpay' ? ('manual' | translate) : ('automatic' | translate)}}</span>
            </ng-container>
            <ng-container *ngIf="selectedFilter !== 'refund'">
              <kwot-currency [price]="(order.productOriginalPrice * (order.quantity || 1)) - order.discountAmount" tag="p"
                             class="mb-0 fs-16 store-content-details" [hasField]="false"
                             type="order" [item]="order"
                             [platformFee]="false"></kwot-currency>
            </ng-container>
          </td>
        </tr>
        </tbody>
        <tbody *ngIf="ordersList.length > 0  && isMobile">
        <tr *ngFor="let order of ordersList">
          <td colspan="9" class="order-details w-100 p-3">
            <div>
              <p class="mb-0 fs-16 cursor-pointer store-content-details"
                 [routerLink]="['/admin', 'orders', 'order-info', order._id]">
                {{order?.user?.firstName}} {{order?.user?.lastName}}
              </p>
            </div>
            <div>
              <div class="d-flex">
                <p class="mb-0 fs-16 store-content-details">
                  #{{order.orderNo}}
                </p>
                <p class="mb-0 fs-16 store-content-details ms-2">
                  {{order.createdAt | date: 'dd/MM/yyyy'}}
                </p>
                <p class="mb-0 fs-16 store-content-details w-100 ms-auto justify-content-end">
              <span class="status {{orderStatus[order.status] | slugify}}" *ngIf="!order?.isReturning">
                <span
                  class="badge"></span>{{order.status == 5 ? ('canceled_by_buyer' | translate) : order.status == 2 ? ('canceled_by_vendor' | translate) : orderStatus[order.status]}}
              </span>
                  <span class="status {{returnOrderStatus[order.returnStatus] | slugify}}" *ngIf="order?.isReturning">
                <span class="badge"></span>{{returnOrderStatus[order.returnStatus]}}
              </span>
                </p>
              </div>
              <p class="mb-0 fs-16 cursor-pointer store-content-details"
                 [routerLink]="['/admin', 'orders', 'order-info', order._id]">
                <img [lazyLoad]="order.seller?.logo || order.seller?.image"
                     *ngIf="(order.seller?.logo || order.seller?.image)"
                     [defaultImage]="'/assets/images/seller_logo.png'" class="search-icon me-2" alt="">
                <span class="store-name-logo list-view" *ngIf="!(order.seller?.logo || order.seller?.image)">
                {{order.seller?.name?.substring(0, 2) | uppercase}}</span>
                {{order.seller?.name}}
              </p>
            </div>
            <div class="">
              <p class="mb-0 fs-16 store-content-details justify-content-between">
                {{order?.product?.productName}}
                <ng-container *ngIf="selectedFilter === 'refund'">
                  <span>
                    <kwot-currency [price]="order.refundPrice" tag="p"
                                   class="mb-0 fs-16 store-content-details ms-auto w-100 justify-content-end"
                                   [hasField]="false"
                                   type="order_static" [item]="order"
                                   [platformFee]="false"></kwot-currency>
                  <span class="fs-14 text-capitalize">{{order.paymentOption}}
                    :- {{order.paymentOption === 'alatpay' ? ('manual' | translate) : ('automatic' | translate)}}</span>
                  </span>
                </ng-container>
                <ng-container *ngIf="selectedFilter !== 'refund'">
                  <kwot-currency [price]="(order.productOriginalPrice * order.quantity) - order.discountAmount" tag="p"
                                 class="mb-0 fs-16 store-content-details ms-auto w-100 justify-content-end"
                                 [hasField]="false"
                                 type="order" [item]="order"
                                 [platformFee]="false"></kwot-currency>
                  <kwot-currency [price]="(order.price * order.quantity)" tag="p"
                                 class="mb-0 fs-16 store-content-details ms-auto w-100 justify-content-end"
                                 [hasField]="false"
                                 type="order" [item]="order"
                                 [platformFee]="false"></kwot-currency>
                </ng-container>
            </div>
          </td>
        </tr>
        </tbody>
      </ng-template>
      <div class="custom-pagination" *ngIf="dataCount !== 0">
        <pagination [boundaryLinks]="true" [rotate]="true" [totalItems]="dataCount"
                    [ngModel]="queryParams.page"
                    [itemsPerPage]="10" [maxSize]="5" (pageChanged)="changePage($event)"></pagination>
      </div>
    </div>
  </div>

  <div class="orders-tabs d-none" >
    <ul class="nav nav-tabs mt-3 w-50" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active w-100 fw-bold" id="order-tab" data-bs-toggle="tab" data-bs-target="#order"
                type="button" role="tab" aria-selected="true" (click)="selectTab('orders')">
          {{'orders' | translate}}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link w-100 fw-bold" id="negotiation-tab" data-bs-toggle="tab"
                data-bs-target="#negotiation" type="button" role="tab" aria-selected="false"
                (click)="getNegotiations('negotiations')">
          {{'negotiation' | translate}}
        </button>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="order" role="tabpanel" aria-labelledby="order-tab">
        <div class="orders-list-wrapper" >
          <h2 class="fs-32 fw-bold ps-sm-0 ps-4">{{'orders' | translate}}</h2>
          <div class="custom-table">
            <div class="tag-filters">
            <span class="tag" [ngClass]="{'active': selectedFilter === filter.value}"
              (click)="filterOrders(filter.value)"
              *ngFor="let filter of filters">{{filter.label}}</span>
            </div>
            <kwot-custom-table
              #customTableComponent
              [customOptions]="customDatatableSettings"
              [ajaxFunction]="getAllOrders"
              [tableHead]="tableHead" [tableBody]="tableBody">
            </kwot-custom-table>
            <ng-template #tableHead>
              <thead *ngIf="!isMobile">
              <tr>
                <td>
                  <p
                    class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                    {{'buyer' | translate}}
                  </p>
                </td>
                <td>
                  <p
                    class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                    {{'seller' | translate}}
                  </p>
                </td>
                <td>
                  <p
                    class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                    {{'order' | translate}}
                  </p>
                </td>
                <td>
                  <p
                    class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                    {{'date_time' | translate}}
                  </p>
                </td>
                <td>
                  <p
                    class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                    {{'status' | translate}}
                  </p>
                </td>
                <td>
                  <p
                    class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                    {{'products' | translate}}
                  </p>
                </td>
                <td>
                  <p
                    class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                    {{'price' | translate}}
                  </p>
                </td>
              </tr>
              </thead>
            </ng-template>
            <ng-template #tableBody>
              <tbody *ngIf="ordersList.length <= 0">
              <tr>
                <td colspan="7">{{'no_data_found' | translate}}</td>
              </tr>
              </tbody>
              <tbody *ngIf="ordersList.length > 0  && !isMobile">
              <tr *ngFor="let order of ordersList">
                <td>
                  <p class="mb-0 fs-16 cursor-pointer store-content-details"
                     [routerLink]="['/admin', 'orders', 'order-info', order._id]">
                    {{order?.user?.firstName}} {{order?.user?.lastName}}
                  </p>
                </td>
                <td>
                  <p class="mb-0 fs-16 cursor-pointer store-content-details" *ngIf="!order?.type"
                     [routerLink]="['/admin', 'orders', 'order-info', order._id]">
                    <img [lazyLoad]="order.seller?.logo || order.seller?.image"
                         *ngIf="(order.seller?.logo || order.seller?.image)"
                         [defaultImage]="'/assets/images/seller_logo.png'" class="search-icon me-2" alt="">
                    <span class="store-name-logo list-view" *ngIf="!(order.seller?.logo || order.seller?.image)">
                {{order.seller?.name?.substring(0, 2) | uppercase}}</span>
                    {{order.seller?.name}}
                  </p>
                  <p class="mb-0 fs-16 cursor-pointer store-content-details" *ngIf="order?.type && order.type === 'digital'"
                     [routerLink]="['/admin', 'orders', 'order-info', order._id]">
                    -
                  </p>
                </td>
                <td>
                  <p class="mb-0 fs-16 store-content-details">
                    #{{order.orderNo}}
                  </p>
                </td>
                <td>
                  <p class="mb-0 fs-16 store-content-details">
                    {{order.createdAt | date: 'dd/MM/yyyy'}}
                  </p>
                </td>
                <td>
                  <p class="mb-0 fs-16 store-content-details">
              <span class="status {{orderStatus[order.status] | slugify}}" *ngIf="!order?.isReturning">
                <span
                  class="badge"></span>{{order.status == 5 ? ('canceled_by_buyer' | translate) : order.status == 2 ? ('canceled_by_vendor' | translate) : orderStatus[order.status]}}
              </span>
                    <span class="status {{returnOrderStatus[order.returnStatus] | slugify}}" *ngIf="order?.isReturning">
                <span class="badge"></span>{{returnOrderStatus[order.returnStatus]}}
              </span>
                  </p>
                </td>
                <td>
                  <p class="mb-0 fs-16 store-content-details" *ngIf="!order?.type">
                    {{order?.product?.productName}}
                  </p>
                  <p class="mb-0 fs-16 store-content-details" *ngIf="order?.type && order?.type === 'digital'">
                    {{'gift_card' | translate}}
                  </p>
                </td>
                <td>
                  <ng-container *ngIf="selectedFilter === 'refund'">
                    <kwot-currency [price]="order.refundPrice" tag="p"
                                   class="mb-0 fs-16 store-content-details" [hasField]="false"
                                   type="order_static" [item]="order"
                                   [platformFee]="false"></kwot-currency>
                    <span class="fs-14 text-capitalize">{{order.paymentOption}}
                      :- {{order.paymentOption === 'alatpay' ? ('manual' | translate) : ('automatic' | translate)}}</span>
                  </ng-container>
                  <ng-container *ngIf="selectedFilter !== 'refund'">
                    <kwot-currency
                      *ngIf="!isB2B"
                       [price]="(order.productOriginalPrice * order.quantity) - order.discountAmount" tag="p"
                       class="mb-0 fs-16 store-content-details" [hasField]="false"
                       type="order" [item]="order"
                       [platformFee]="false">
                    </kwot-currency>
                    <kwot-currency
                      *ngIf="isB2B"
                      [price]="(order.price * order.quantity)" tag="p"
                       class="mb-0 fs-16 store-content-details" [hasField]="false"
                       type="order" [item]="order"
                       [platformFee]="false">
                    </kwot-currency>
                  </ng-container>
                </td>
              </tr>
              </tbody>
              <tbody *ngIf="ordersList.length > 0  && isMobile">
              <tr *ngFor="let order of ordersList">
                <td colspan="9" class="order-details w-100 p-3">
                  <div>
                    <p class="mb-0 fs-16 cursor-pointer store-content-details"
                       [routerLink]="['/admin', 'orders', 'order-info', order._id]">
                      {{order?.user?.firstName}} {{order?.user?.lastName}}
                    </p>
                  </div>
                  <div>
                    <div class="d-flex">
                      <p class="mb-0 fs-16 store-content-details">
                        #{{order.orderNo}}
                      </p>
                      <p class="mb-0 fs-16 store-content-details ms-2">
                        {{order.createdAt | date: 'dd/MM/yyyy'}}
                      </p>
                      <p class="mb-0 fs-16 store-content-details w-100 ms-auto justify-content-end">
              <span class="status {{orderStatus[order.status] | slugify}}" *ngIf="!order?.isReturning">
                <span
                  class="badge"></span>{{order.status == 5 ? ('canceled_by_buyer' | translate) : order.status == 2 ? ('canceled_by_vendor' | translate) : orderStatus[order.status]}}
              </span>
                        <span class="status {{returnOrderStatus[order.returnStatus] | slugify}}" *ngIf="order?.isReturning">
                <span class="badge"></span>{{returnOrderStatus[order.returnStatus]}}
              </span>
                      </p>
                    </div>
                    <p class="mb-0 fs-16 cursor-pointer store-content-details"
                       [routerLink]="['/admin', 'orders', 'order-info', order._id]">
                      <img [lazyLoad]="order.seller?.logo || order.seller?.image"
                           *ngIf="(order.seller?.logo || order.seller?.image)"
                           [defaultImage]="'/assets/images/seller_logo.png'" class="search-icon me-2" alt="">
                      <span class="store-name-logo list-view" *ngIf="!(order.seller?.logo || order.seller?.image)">
                {{order.seller?.name?.substring(0, 2) | uppercase}}</span>
                      {{order.seller?.name}}
                    </p>
                  </div>
                  <div class="">
                    <p class="mb-0 fs-16 store-content-details justify-content-between">
                      {{order?.product?.productName}}
                      <ng-container *ngIf="selectedFilter === 'refund'">
                  <span>
                    <kwot-currency [price]="order.refundPrice" tag="p"
                                   class="mb-0 fs-16 store-content-details ms-auto w-100 justify-content-end"
                                   [hasField]="false"
                                   type="order_static" [item]="order"
                                   [platformFee]="false"></kwot-currency>
                  <span class="fs-14 text-capitalize">{{order.paymentOption}}
                    :- {{order.paymentOption === 'alatpay' ? ('manual' | translate) : ('automatic' | translate)}}</span>
                  </span>
                      </ng-container>
                      <ng-container *ngIf="selectedFilter !== 'refund'">
                        <kwot-currency [price]="(order.productOriginalPrice * order.quantity) - order.discountAmount" tag="p"
                                       class="mb-0 fs-16 store-content-details ms-auto w-100 justify-content-end"
                                       [hasField]="false"
                                       type="order" [item]="order"
                                       [platformFee]="false"></kwot-currency>
                      </ng-container>
                  </div>
                </td>
              </tr>
              </tbody>
            </ng-template>
            <div class="custom-pagination" *ngIf="dataCount !== 0">
              <pagination [boundaryLinks]="true" [rotate]="true" [totalItems]="dataCount"
                          [ngModel]="queryParams.page"
                          [itemsPerPage]="10" [maxSize]="5" (pageChanged)="changePage($event)"></pagination>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="negotiation" role="tabpanel" aria-labelledby="payout-details">
        <ng-container *ngIf="negotiationList.length > 0">
          <div class="orders-list-wrapper" >
            <h2 class="fs-32 fw-bold ps-sm-0 ps-4">{{'rfq_negotiation' | translate}}</h2>
            <div class="custom-table">
              <kwot-custom-table
                #customTableComponent
                [customOptions]="customDatatableSettings"
                [ajaxFunction]="getNegotiations"
                [tableHead]="tableHeadNegotiation" [tableBody]="tableBodyNegotiation">
              </kwot-custom-table>
              <ng-template #tableHeadNegotiation>
                <thead *ngIf="!isMobile">
                <tr>
                  <td>
                    <p
                      class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                      {{'rfq_type' | translate}}
                    </p>
                  </td>
                  <td>
                    <p
                      class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                      {{'date' | translate}}
                    </p>
                  </td>
                  <td>
                    <p
                      class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                      {{'status' | translate}}
                    </p>
                  </td>
                </tr>
                </thead>
              </ng-template>
              <ng-template #tableBodyNegotiation>
                <tbody *ngIf="negotiationList.length <= 0">
                <tr>
                  <td colspan="7">{{'no_data_found' | translate}}</td>
                </tr>
                </tbody>
                <tbody *ngIf="negotiationList.length > 0  && !isMobile">
                <tr *ngFor="let negotiation of negotiationList">
                  <td>
                    <p class="mb-0 fs-16 cursor-pointer store-content-details text-capitalize"
                       [routerLink]="['/admin' ,'rfq-negotiation', negotiation._id]">
                      {{negotiation?.negotiationOfferType}}
                    </p>
                  </td>
                  <td>
                    <p class="mb-0 fs-16 cursor-pointer store-content-details">
                      {{negotiation.createdAt | date: 'dd/MM/yyyy'}}
                    </p>
                  </td>
                  <td>
                    <p class="mb-0 fs-16 store-content-details">
                      <span class="status rfq-negotiation" *ngIf="negotiation?.status === 'initiated' || negotiation?.status === 'in negotiation'">
                        <span class="badge"></span> {{'rfq_negotiation' | translate}}
                      </span>
                      <span class="status delivered" *ngIf="negotiation?.status === 'approved by admin'">
                        <span class="badge"></span> {{'approved' | translate}}
                      </span>
                    </p>
                  </td>
                </tr>
                </tbody>
                <tbody *ngIf="negotiationList.length > 0  && isMobile">
                <tr *ngFor="let negotiation of negotiationList">
                  <td colspan="9" class="order-details w-100 p-3">
                    <div>
                      <p class="mb-0 fs-16 cursor-pointer store-content-details text-capitalize"
                         [routerLink]="['/admin' ,'rfq-negotiation', negotiation._id]">
                        {{negotiation?.negotiationOfferType}}
                      </p>
                    </div>
                    <div>
                      <p class="mb-0 fs-16 store-content-details ms-2">
                        {{negotiation?.createdAt | date: 'dd/MM/yyyy'}}
                      </p>
                    </div>
                    <div>
                      <p class="mb-0 fs-16 store-content-details">
                      <span class="status rfq-negotiation" *ngIf="negotiation?.status === 'initiated' || negotiation?.status === 'in negotiation'">
                        <span class="badge"></span> {{'rfq_negotiation' | translate}}
                      </span>
                        <span class="status delivered" *ngIf="negotiation?.status === 'approved by admin'">
                        <span class="badge"></span> {{'approved' | translate}}
                      </span>
                      </p>
                    </div>
                  </td>
                </tr>
                </tbody>
              </ng-template>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
